import './Projects.css';
import level20 from '../../resources/projects/level20.webp';
import cityPDG from '../../resources/projects/city_01.imagemagick1.0.webp';
import lab from '../../resources/projects/lab-2.webp';
import bomberman from '../../resources/projects/bomberman.webp';
import terrorist from '../../resources/projects/csgo.webp';
import amazon from '../../resources/projects/amazon.mp4';
import elevation from '../../resources/projects/elevation.mp4';
import density from '../../resources/projects/density.mp4';
import tavira from '../../resources/projects/tavira.mp4';
import predictiveAvoidance from '../../resources/projects/path.mp4';
import LGCA from '../../resources/projects/latticeautomata.mp4';
import GM from '../../resources/projects/granularmaterials.mp4';
import BM from '../../resources/projects/BM.mp4';
import geneticAlgorithm from '../../resources/projects/genetic-algorithm-svgrepo-com.svg';
import faceScan from '../../resources/projects/face-scan-square-svgrepo-com.svg';
import problemSolving from '../../resources/projects/idea-invention-svgrepo-com.svg';
import neuralNetwork from '../../resources/projects/neuron-connections-neural-network-svgrepo-com.svg';
import unity from '../../resources/projects/unity2.webp';
import houdini from '../../resources/projects/houdini2.webp';
import maya from '../../resources/projects/maya2.webp';
import render from '../../resources/projects/render.png';
import VerticalProject from './VerticalProject';
import Pill from './Pill';

const Projects = () => {
  return (
    <div className="projects">
      <h1 className="projects-title">PROJECTS</h1>
      <div className="prueba">
        <VerticalProject
          title={'Online Game Connection Framework '}
          description="Counter Striker Global Offense (now CS2) recreation. Implemented the base code to allow
        for online gaming in Unity (C#) with UDP sockets. Took into account snapshot
        interpolation, prediction, jitter and data compression. Used 8-Bit visuals it includead
        deathmatch and team modes, multiple weapons as well as
        death notification on death."
          image={terrorist}
          logo={unity}
        />
        <VerticalProject
          title={'Zombie FPS Survival Game'}
          description="Zombiestance is a first person shooter survival game played in waves against zombie characters. It was developed in Unity and it is played in rounds with different health and power-up drops spawning on different rounds as well as boss enemies with different skills. With a menu that allows playing with different hero characters it also showed a minimap above and
            direction of hits."
          logo={unity}
          image={level20}
        />
        <VerticalProject
          title={'Bomberman (NES)'}
          description="Bomberman (NES) recreation made in Unity consists of a board made up of breakable and unbreakable walls. There are five different implemented bonuses appearing at random one per level and hidden behind breakable walls as well as three different enemy types."
          image={bomberman}
          logo={unity}
        />
        <VerticalProject
          title={'City Builder PDG'}
          description="Built a procedural city with Task Operators (TOPs) using PDG technology in Houdini. Starting from a city map, used TOP nodes to create randomized buildings for each city block and then grow the system to wedge the position of the city core as well as the city map reference to generate multiple cities."
          image={cityPDG}
          logo={houdini}
        />
        <VerticalProject
          title={'Optitrack Motion Capture'}
          description="Using a Optitrack Motion Capture setup, recorded multiple motion capture animations recreating iconic moments from films such as Moana, Mission Impossible and more. Performed calibration and clean up and then utilizing Autodesk MotionBuilder with the Optitrack plugin, exported the animation for later rendering."
          image={lab}
          logo={maya}
        />
        <VerticalProject
          title={'Maya MOCAP Rendering'}
          description="Animated 3D character using HumanIK in Autodesk's Maya.
          Captured myself in the motion capture lab imitating the guns reloaded scene from Mission Impossible. Imported the captured animation into Autodesk Maya and created a character definition to drive a rig binded to a model. Set up a render of the final result."
          image={render}
          logo={maya}
        />
      </div>
      <div id="ss" className="ss">
        <h2>SYSTEM SIMULATIONS</h2>
        <div className="wildfire-container">
          <div className="wildfire-description-container">
            <h4 className="ss-subtitle">Wildfire Spread</h4>
            <p className="wildfire-description">
              Wildfire simulation based on a cellular automata model using information extracted
              from Raster files taking into account vegetation, density, elevation, wind speed and
              direction as well as spotting.{' '}
              <span className="wildfire-desc-extra">
                Using a math model the probability of an adjacent cell catching fire, given a
                burning cell was calculatedIt was used on real life examples such as the Amazon
                rainforest (South America) to simulate how a wildfire would behave.
              </span>
            </p>
          </div>
          <div className="flex">
            <div className="col-left">
              <video width="640" height="480" autoPlay muted loop src={amazon} />
            </div>
            <div className="col">
              <video autoPlay loop muted width="600" height="450" src={elevation} />
              <video autoPlay loop muted width="600" height="450" src={tavira} />
              <video autoPlay loop muted width="600" height="450" src={density} />
            </div>
          </div>
        </div>

        <div className="systems-projects">
          <div className="ss-project">
            <div className="ss-title">Predictive Collision Avoidance</div>
            <video autoPlay width="600" height="450" loop muted src={predictiveAvoidance} />
          </div>
          <div className="ss-project">
            <div className="ss-title"> Lattice Gas Cellular Automata</div>
            <video autoPlay width="600" height="450" loop muted src={LGCA} />
          </div>
          <div className="ss-project">
            <div className="ss-title">Granular Materials</div>
            <video autoPlay width="600" height="450" loop muted src={GM} />
          </div>
          <div className="ss-project">
            <div className="ss-title">Brownian Motion</div>
            <video autoPlay width="600" height="450" loop muted src={BM} />
          </div>
        </div>
      </div>

      <div id="sia" className="artificial-intelligence">
        <h2>ARTIFICIAL INTELLIGENCE</h2>
        <div className="ai-projects">
          <div className="ai-project">
            <img width="100px" src={faceScan} />
            <p>Face and emotion recognition using SVD, PCA and KPCA</p>
          </div>
          <div className="ai-project">
            <img width="100px" src={geneticAlgorithm} />
            <p>GA for maximizing character fitness by modifying its gear</p>
          </div>
          <div className="ai-project">
            <img width="100px" src={neuralNetwork} />
            <p>Neural network topology discovery based on height distribution matrix</p>
          </div>
          <div className="ai-project">
            <img width="100px" src={problemSolving} />
            <p>GPS engine with informed and non-informed search methods</p>
          </div>
        </div>
      </div>

      <h2>MORE ABOUT ME</h2>
      <div id="creative" className="creative">
        <Pill title="PHOTOGRAPHY" alternate="Film and photography production" />
        <Pill title="THEATRE" alternate="Directing and acting in musical theatre" />
        <Pill title="SPORTS" alternate="Hockey team co-founder and captain" />
        <Pill title="VOLUNTEERING" alternate="Developed app for non-profit and volunteering" />
      </div>
    </div>
  );
};

export default Projects;
