import './Description.css';

const Description = () => {
  return (
    <div className="description">
      <span className="highlight-container">
        <span className="highlight">
          <div className="description-title">HI THERE!</div>
        </span>
      </span>
      <p>
        I'm a Software Engineer graduate from Instituto Tecnológico de Buenos Aires. I'm extremely
        passionate about computer graphics and animation and being able to mix technology and
        artistry. Having grown up in an artistically-rich environment where I got to learn and
        experience different art forms such as film, theatre and music and having studied at one of
        the top universities in Argentina, I find myself being able to combine skills from both
        areas and put them into whatever I set my hands on. Ever curious I'm always on the lookout
        for new challenges to grow from and opportunities to work along like-minded individuals.
      </p>
    </div>
  );
};

export default Description;
